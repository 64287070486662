import {useContext, useEffect, useReducer, useState} from "react";


export default function GoogleForm() {

    const server_host = process.env.NODE_ENV === 'development' ? 'http://localhost:9000' : 'https://api.js-course.ru'

    useEffect(() => {
        console.log(process.env.NODE_ENV)
    }, [])
    return (
        <div className={'google-button-wrapper'}>
            <a className={'button-href'} href={server_host + '/oauth/google/'}>Войти через Google</a>
        </div>

    )

}
