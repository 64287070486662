import {Button} from "antd";

export default function TelegramCheckPointPage({server_host}) {
    return (
        <div className={'container text-center'}>
            <h1>Подключите Telegram</h1>
            <div className={'subheader'}>Вы будете получать дополнительную информацию через бот. Обновите страницу после подключения.</div>
            <div>
                <a href={server_host + "/telegram/connect"}><Button>Подключить</Button></a>
            </div>
        </div>
    )
}