import {useReducer} from "react";
import {InitialState} from "./InitialState";
import {AppContext} from "./AppContext";
import {Reducer} from "./Reducer";

export default function Store({children}) {
    const [state, dispatch] = useReducer(Reducer, InitialState);
    return (
        <AppContext.Provider value={[state, dispatch]}>
            {children}
        </AppContext.Provider>
    )
}
