import * as React from "react";
import {Link, Route, Routes} from "react-router-dom";
import Home from "./components/Home";
import {lazy, Suspense} from "react";
import TelegramCheckPointPage from "./components/pages/checkpoints/TelegramCheckPointPage";

/*
import PaymentsPage from "./components/pages/admin/PaymentsPage";
import CoursePage from "./components/pages/CoursePage";
import Agreement from "./components/pages/Agreement";
import PaymentResult from "./components/pages/PaymentResult";
import SendEmailPage from "./components/pages/admin/SendEmailPage";
import UnsubscribedPage from "./components/pages/UnsubscribedPage";
import AddProductPage from "./components/pages/admin/AddProductPage";
import ProductPage from "./components/pages/ProductPage";
import AdminProductsPage from "./components/pages/admin/AdminProductsPage";
import ProductsPage from "./components/pages/ProductsPage";
import ShowCasePage from "./components/pages/ShowCasePage";
import LoginPage from "./components/pages/LoginPage";
import BuyPage from "./components/pages/BuyPage";
import FilesPage from "./components/pages/admin/FilesPage";
import AdminRoute from "./components/pages/AdminRoute";
import AllYoutubeLessonsPage from "./components/pages/AllYoutubeLessonsPage";
import AddCoursePage from "./components/pages/AddCoursePage";
import AllCoursesAdminPage from "./components/pages/AllCoursesAdminPage";
import CoursesPage from "./components/pages/CoursesPage";
import UsersPage from "./components/pages/admin/UsersPage";

import AddYoutubeLessonPage from "./components/pages/AddYoutubeLessonPage";
import LessonsPage from "./components/pages/LessonsPage";
import AddCourseLessonPage from "./components/pages/AddCourseLessonPage";
import AdminPage from "./components/pages/admin/AdminPage";
import DashboardPage from "./components/pages/DashboardPage";
 */





const AddYoutubeLessonPage = lazy(() => import("./components/pages/AddYoutubeLessonPage"))
const LessonsPage = lazy(() => import("./components/pages/LessonsPage"))
const AddCourseLessonPage = lazy(() => import("./components/pages/AddCourseLessonPage"))
const AdminPage = lazy(() => import("./components/pages/admin/AdminPage"))
const DashboardPage = lazy(() => import("./components/pages/DashboardPage"))
const AdminRoute = lazy(() => import("./components/pages/AdminRoute"))
const AllYoutubeLessonsPage = lazy(() => import("./components/pages/AllYoutubeLessonsPage"))
const AddCoursePage = lazy(() => import("./components/pages/AddCoursePage"))
const AllCoursesAdminPage = lazy(() => import("./components/pages/AllCoursesAdminPage"))
const CoursesPage = lazy(() => import("./components/pages/CoursesPage"))
const UsersPage = lazy(() => import("./components/pages/admin/UsersPage"))
const PaymentsPage = lazy(() => import("./components/pages/admin/PaymentsPage"))
const CoursePage = lazy(() => import("./components/pages/CoursePage"))
const Agreement = lazy(() => import("./components/pages/Agreement"))
const PaymentResult = lazy(() => import("./components/pages/PaymentResult"))
const SendEmailPage = lazy(() => import("./components/pages/admin/SendEmailPage"))
const SendTelegramPage = lazy(() => import("./components/pages/admin/SendTelegramPage"))
const UnsubscribedPage = lazy(() => import("./components/pages/UnsubscribedPage"))
const AddProductPage = lazy(() => import("./components/pages/admin/AddProductPage"))
const ProductPage = lazy(() => import("./components/pages/ProductPage"))
const AdminProductsPage = lazy(() => import("./components/pages/admin/AdminProductsPage"))
const ProductsPage = lazy(() => import("./components/pages/ProductsPage"))
const ShowCasePage = lazy(() => import("./components/pages/ShowCasePage"))
const LoginPage = lazy(() => import("./components/pages/LoginPage"))
const BuyPage = lazy(() => import("./components/pages/BuyPage"))
const FilesPage = lazy(() => import("./components/pages/admin/FilesPage"))
const TelegramWebAppPage = lazy(() => import("./components/pages/TelegramWebAppPage"))


function App() {

    const server_host = process.env.NODE_ENV === 'development' ? 'http://localhost:9000' : 'https://api.js-course.ru'

    return (
        <Routes>
            {/*<Route path="/" element={<Home/>}/>*/}
            <Route path="/" element={<Suspense fallback={<></>}><ShowCasePage server_host={server_host}/></Suspense>}/>
            <Route path="/login" element={<Suspense fallback={<></>}><LoginPage server_host={server_host}/></Suspense>}/>
            <Route path="/agreement" element={<Suspense fallback={<></>}><Agreement/></Suspense>}/>
            <Route path="/unsubscribed" element={<Suspense fallback={<></>}><UnsubscribedPage/></Suspense>}/>
            <Route path="admin" element={<Suspense fallback={<></>}><AdminRoute/></Suspense>} >
                <Route path="" element={<Suspense fallback={<></>}><AdminPage/></Suspense>}/>
                <Route path="lessons/youtube/add" element={<Suspense fallback={<></>}><AddYoutubeLessonPage/></Suspense>}/>
                <Route path="lessons/youtube/edit/:id" element={<Suspense fallback={<></>}><AddYoutubeLessonPage/></Suspense>}/>
                <Route path="lessons/youtube/all" element={<Suspense fallback={<></>}><AllYoutubeLessonsPage/></Suspense>}/>
                <Route path="courses/all" element={<Suspense fallback={<></>}><AllCoursesAdminPage/></Suspense>}/>
                <Route path="courses/add" element={<Suspense fallback={<></>}><AddCoursePage/></Suspense>}/>
                <Route path="courses/edit/:id" element={<Suspense fallback={<></>}><AddCoursePage/></Suspense>}/>
                <Route path="products/all" element={<Suspense fallback={<></>}><AdminProductsPage/></Suspense>}/>
                <Route path="products/add" element={<Suspense fallback={<></>}><AddProductPage/></Suspense>}/>
                <Route path="products/edit/:id" element={<Suspense fallback={<></>}><AddProductPage/></Suspense>}/>
                <Route path="files" element={<Suspense fallback={<></>}><FilesPage server_host={server_host}/></Suspense>}/>

                <Route path="courses/add/lesson" element={<Suspense fallback={<></>}><AddCourseLessonPage/></Suspense>}/>
                <Route path="users" element={<Suspense fallback={<></>}><UsersPage/></Suspense>}/>
                <Route path="payments" element={<Suspense fallback={<></>}><PaymentsPage/></Suspense>}/>
                <Route path="email/send" element={<Suspense fallback={<></>}><SendEmailPage/></Suspense>}/>
                <Route path="telegram/send" element={<Suspense fallback={<></>}><SendTelegramPage/></Suspense>}/>
            </Route>

            <Route path="payments/result/:value" element={<Suspense fallback={<></>}><PaymentResult/></Suspense>}/>
            <Route path="payments/checkout/:type/:id" element={<Suspense fallback={<></>}><BuyPage server_host={server_host}/></Suspense>}/>

            <Route path="courses">
                <Route path="" element={<Suspense fallback={<></>}><CoursesPage/></Suspense>}/>
                <Route path="show/:id" element={<Suspense fallback={<></>}><CoursePage/></Suspense>}/>
            </Route>
            <Route path="products">
                <Route path="" element={<Suspense fallback={<></>}><ProductPage/></Suspense>}/>
                <Route path="type/:type" element={<Suspense fallback={<></>}><ProductsPage server_host={server_host}/></Suspense>}/>
                <Route path="show/:id" element={<Suspense fallback={<></>}><ProductPage server_host={server_host}/></Suspense>}/>
            </Route>
            <Route path="lessons" element={<Suspense fallback={<></>}><LessonsPage/></Suspense>}/>
            <Route path="dashboard" element={<Suspense fallback={<></>}><DashboardPage/></Suspense>}/>
            <Route path="telegramWebApp" element={<Suspense fallback={<></>}><TelegramWebAppPage server_host={server_host}/></Suspense>}/>
        </Routes>
    );
}

export default App;
