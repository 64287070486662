import {useEffect, useState} from "react";
import { YMInitializer } from 'react-yandex-metrika';
import ym from 'react-yandex-metrika';
import {useLocation, useParams} from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';

const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching

const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
};

ReactPixel.init('390627344702303', advancedMatching, options);

export default function AnalyticsComponent() {

    const server_host = process.env.NODE_ENV === 'development' ? 'http://localhost:9000' : 'https://api.js-course.ru'
    const loc = useLocation()

    useEffect(sendHit, [])
    useEffect(sendYMHit, [])
    useEffect(sendFBHit, [])

    function uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    function getTemporaryUser() {
        let temporaryUser = localStorage.getItem("temporaryUser");
        if (!temporaryUser) {
            localStorage.setItem("temporaryUser", uuidv4());
            temporaryUser = localStorage.getItem("temporaryUser");
        }
        return temporaryUser;
    }

    async function sendHit() {
        await fetch(server_host + '/analytics/hit', {
            credentials: "include",
            method: 'post',
            body: JSON.stringify(
                {
                    referrer: document.referrer,
                    url: document.location.href,
                    temporaryUser: getTemporaryUser()
                }
            ),
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    function sendYMHit() {
        ym('hit', loc.pathname)
    }

    function sendFBHit() {
        ReactPixel.pageView(); // For tracking page view
    }



    return (
        <>
            <YMInitializer accounts={[90062392]} />
        </>
    )
}