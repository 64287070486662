export const Reducer = (state, action) => {
    switch (action.type) {
        case 'setComments':
            return {
                ...state,
                comments: action.payload,
            }
        default:
            throw new Error("Действие " + action + " не найдено")
    }
}
