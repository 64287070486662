import {useContext, useEffect, useReducer, useState,} from "react";
import {AppContext} from "../management/AppContext";
import * as EmailValidator from 'email-validator';
import { useNavigate } from "react-router-dom";



export default function Form() {

    const server_host = process.env.NODE_ENV === 'development' ? 'http://localhost:9000' : 'https://api.js-course.ru'

    const [state, dispatch] = useContext(AppContext)
    const [email, setEmail] = useState('')
    const [code, setCode] = useState('')
    const [disabled, setDisabled] = useState(false)
    const [errorStyle, setErrorStyle] = useState({})
    const [status, setStatus] = useState('')
    const [mode, setMode] = useState('email')

    let navigate = useNavigate();

    useEffect(() => {
        checkCode()
    }, [code])

    async function sendCode() {
        setDisabled(true)
        console.log(email);
        if (!EmailValidator.validate(email)) {
            setDisabled(false)
            setErrorStyle({borderColor: 'red'})
            setStatus('неверный email')
            setTimeout(() => {
                setErrorStyle({})
                setStatus('')
            }, 3000)
            return
        }

        try {
            setStatus('Отправка ...')
            const response = await fetch(server_host + "/users/sendCode", {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email
                })
            })
            const data = await response.json()
            if (data.ok) {
                setMode('code');
                setDisabled(false)
                setStatus('Код отправлен на ' + email + "")
            }
        } catch (e) {
            console.error(e)
            console.log("Ошибка отправки кода")
            setDisabled(false)
            setStatus('Ошибка. Попробуйте повторить позже.')
        }
    }

    async function checkCode() {
        if (code.length > 4) {
            setStatus('Код слишком длинный')
        } else if (code.length === 4) {
            setDisabled(true)
            setStatus("Проверка")

            try {
                const response = await fetch(server_host + '/users/checkCode', {
                    body: JSON.stringify({
                        code: code,
                        email: email
                    }),
                    credentials : "include",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: 'post'
                })
                const data = await response.json();

                if (data.ok === true) {
                    setStatus('Код верный. Выполняется вход.')
                    const response = await fetch(server_host + "/users/auth?token=" + data.token, {
                        credentials : "include",
                    })
                    const json = await response.json();
                    if (json.ok === true) {
                        navigate('/')
                        //navigate('/dashboard')
                        //document.location.href = '/course'
                    } else {
                        setStatus('Код неверный')
                        setDisabled(false)
                    }
                    //document.location.href = server_host + "/users/auth?token=" + data.token;
                } else {
                    setStatus('Код неверный')
                    setDisabled(false)
                }
            } catch (e) {
                console.error(e)
                setStatus('Ошибка.  Попробуйте еще раз.')
                setDisabled(false)
            }

        }
    }

    function changeMode() {
        mode === 'email' ? setMode('code') : setMode('email')
    }

    return (
        <>
            <div className={'subheader'}>{mode === 'email' ? 'Введите email, чтобы получить код для входа.' : 'Введите код'}</div>
            <form>

                <div>
                    {mode === 'email' ?
                        <input type={'email'} disabled={disabled} style={errorStyle} className={'email'}
                               placeholder={'ok@js-course.ru'} value={email}
                               onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                               onChange={(e) => setEmail(e.target.value)}/> :
                        <input type={'text'} disabled={disabled}
                               style={errorStyle} className={'email'} placeholder={'0000'}
                               value={code}
                               onChange={(e) => setCode(e.target.value)}/>
                    }
                </div>


                <div className={'status'}>{status}</div>
                <div>
                    {mode === 'email' ?
                        <button type={'button'} disabled={disabled} onClick={sendCode} className={'button'}>Получить
                            код</button> :
                        <div>Если код не приходит, напишите на ok@js-course.ru</div>
                    }
                </div>
            </form>
        </>

    )
}
